import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type {
  IAppointmentAvailableMonthly,
  IAppointmentAvailableMonthlyResponse,
  IAppointmentAvailableTimeslot as IAppointmentAvailableTimeslots,
  IAppointmentAvailableTimeslotResponse as IAppointmentAvailableTimeslotsResponse,
  IAppointmentMakeAnswer,
  IAppointmentMakeFormResponse,
  IAppointmentTimeSlot,
  IGetNewTimeSlot,
  INewTimeSlot,
} from "domains/interfaces/appointment"
import type { IAssessmentUserResult } from "domains/interfaces/assessment"
import type { IForm } from "domains/interfaces/form"
import type {
  Appointment,
  AppointmentResponse,
  ISlotPayload,
} from "domains/interfaces/telemed/appointment/appointment"
import { inject, injectable } from "inversify"
import moment from "moment"
import type { IApiService } from "./apiService"
import type { IDateService } from "./dateService"
import type { IFileService } from "./fileService"
import type { IFormService } from "./formService"
import type { IHelperService } from "./helperService"
import type { IProfileService } from "./profileService"
import { TYPES } from "./types"

export interface IAppointmentService {
  getMakeForm(): Promise<{ form: IForm; timeSlots: IAppointmentTimeSlot[] }>
  getAppointmentTimeSlot(from: string, to: string): Promise<IAppointmentTimeSlot[]>
  isAssessmentRequired(result: IAssessmentUserResult): boolean
  makeAppointment(answer: IAppointmentMakeAnswer): Promise<any>
  getInitialAppointmentAnswer(makeForm: IForm): Promise<any>
  getUserInitialAppointmentAnswer(makeForm: IForm, userId: any): Promise<any>
  getById(id: number): Promise<Appointment>
  saveAppointmentSlot(appointmentId: number, slot: ISlotPayload): Promise<any>
  removeAppointmentSlot(appointmentId: number): Promise<any>
  cancelAppointment(appointmentId: number, note: string): Promise<void>
  getAvaliableCounsellor(from: any, to: any): Promise<any>
  getAvaliableSlot(from: any, to: any): Promise<any>
  getWaitingRoomCount(): Promise<number>
  getAppointmentCreatable(): Promise<any>

  /**
   * * Note: Telemed Add
   */
  getAvailableMonthly(
    staffType: string,
    month: number,
    year: number
  ): Promise<IAppointmentAvailableMonthly>

  getAvailableTimeslots(staffType: string, date: string): Promise<IAppointmentAvailableTimeslots>
  getTimeSlotFromTo(params: IGetNewTimeSlot): Promise<INewTimeSlot[]>
}

@injectable()
export class AppointmentService {
  private apiService: IApiService
  private dateService: IDateService
  private profileService: IProfileService
  private formService: IFormService
  private helperService: IHelperService
  private fileService: IFileService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.IDateService) dateService: IDateService,
    @inject(TYPES.IProfileService) profileService: IProfileService,
    @inject(TYPES.IFormService) formService: IFormService,
    @inject(TYPES.IHelperService) helperService: IHelperService,
    @inject(TYPES.IFileService) fileService: IFileService
  ) {
    this.apiService = apiService
    this.dateService = dateService
    this.profileService = profileService
    this.formService = formService
    this.helperService = helperService
    this.fileService = fileService
  }

  public async getAppointmentTimeSlot(from: string, to: string): Promise<IAppointmentTimeSlot[]> {
    let apiFilter = { fromDate: from, toDate: to }
    const response = await this.apiService.get<any>("appointment/timeslots", apiFilter, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    let formatData: any = response.data.data
    // console.log(formatData.timeSlots)
    return formatData.timeSlots!
  }

  public async getMakeForm(): Promise<{ form: IForm; timeSlots: IAppointmentTimeSlot[] }> {
    const response = await this.apiService.get<IAppointmentMakeFormResponse>(
      "appointment/form",
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    let formatData: any = response.data.data
    formatData.form.questions = formatData.form.questions.map((question: any) => {
      if (question.question_en === "Province") {
        return {
          ...question,
          choices: question.choices.sort((a: any, b: any) => {
            return a.description_th.localeCompare(b.description_th)
          }),
        }
      } else return question
    })
    return formatData!
  }

  public isAssessmentRequired(result: IAssessmentUserResult): boolean {
    const createdDate = this.dateService.convertToJSDate(result.created_at)
    return createdDate.add(2, "weeks").isBefore(moment())
  }

  public async makeAppointment(answer: IAppointmentMakeAnswer): Promise<void> {
    const response = await this.apiService.post<any>("appointment", answer, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { appointment } = response.data.data
    return appointment
  }

  public async getInitialAppointmentAnswer(makeForm: IForm): Promise<any> {
    const { medicalHistory, userProfile, userInfo } = await this.profileService.getUserProfile(
      "form"
    )
    const profileForm = await this.profileService.getProfileForm()
    const initialAnswer: any = {
      Firstname: userInfo.firstname,
      Lastname: userInfo.lastname,
      Email: userInfo.email,
      idnumber: userInfo.idnumber,
      nationality: userInfo.nationality?.id,
      Faculty: userInfo.faculty?.id,
      idnumber_type: userInfo.idnumber_type?.id,
      ...this.formService.mapFormAnswerWithSameQuestion(
        profileForm.userProfileForm,
        makeForm,
        userProfile
      ),
      ...this.formService.mapFormAnswerWithSameQuestion(
        profileForm.medicalHistoryProfile,
        makeForm,
        medicalHistory
      ),
    }
    return initialAnswer
  }

  public async getUserInitialAppointmentAnswer(makeForm: IForm, userId: any): Promise<any> {
    const { medicalHistory, userProfile, userInfo } = await this.profileService.getUserProfile(
      "form",
      userId
    )
    const profileForm = await this.profileService.getProfileForm()
    const initialAnswer: any = {
      Firstname: userInfo.firstname,
      Lastname: userInfo.lastname,
      Email: userInfo.email,
      nationality: userInfo.nationality,
      ...this.formService.mapFormAnswerWithSameQuestion(
        profileForm.userProfileForm,
        makeForm,
        userProfile
      ),
      ...this.formService.mapFormAnswerWithSameQuestion(
        profileForm.medicalHistoryProfile,
        makeForm,
        medicalHistory
      ),
    }
    return initialAnswer
  }

  public async getById(id: number): Promise<Appointment> {
    const response = await this.apiService.get<AppointmentResponse>(`appointment/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data?.appointment) {
      throw new Error(response.data.message)
    }

    return response.data.data.appointment
  }

  public async saveAppointmentSlot(appointmentId: number, slotData: ISlotPayload): Promise<any> {
    const response = await this.apiService.post<any>(`appointment/${appointmentId}`, slotData, true)
    if (response.status === ApiResponseStatus.NotFound) {
      // console.log(response);
      return response.data.message
    }
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return null
  }

  public async removeAppointmentSlot(appointmentId: number): Promise<any> {
    const response = await this.apiService.delete<any>(`appointment/${appointmentId}`, {}, true)
    if (response.status === ApiResponseStatus.NotFound) {
      // console.log(response);
      return response.data.message
    }
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return null
  }

  public async cancelAppointment(appointmentId: number, note: string): Promise<void> {
    const response = await this.apiService.post<any>(
      `appointment/${appointmentId}/cancel`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getAvaliableCounsellor(from: any, to: any): Promise<any> {
    const response = await this.apiService.get<any>(
      "appointment/counseller/avaliable",
      { from, to },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    // return response.data.data

    return response.data.data.map((slot: any) => {
      return {
        ...slot,
        avatar_image: this.fileService.getFileUrl(slot.avatar_image),
      }
    })
  }

  public async getAvaliableSlot(from: any, to: any): Promise<any> {
    const response = await this.apiService.get<any>(
      "appointment/slot/avaliable",
      { from, to },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getWaitingRoomCount(): Promise<number> {
    const response = await this.apiService.get<any>("appointment/counterwaitingroom", {})
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.count
  }

  public async getAppointmentCreatable(): Promise<any> {
    const response = await this.apiService.get<any>("appointment/creatable", {})
    if (response.status !== ApiResponseStatus.Ok) {
      console.error(response.status, response.data.message)
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  /*
   * * Note: Telemed Add
   */

  public async getAvailableMonthly(
    staffType: string,
    month: number,
    year: number
  ): Promise<IAppointmentAvailableMonthly> {
    const response = await this.apiService.get<IAppointmentAvailableMonthlyResponse>(
      `appointment/${staffType}/monthly?month=${month}&year=${year}`
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getAvailableTimeslots(
    staffType: string,
    date: string
  ): Promise<IAppointmentAvailableTimeslots> {
    const response = await this.apiService.get<IAppointmentAvailableTimeslotsResponse>(
      `appointment/${staffType}/timeslots?date=${date}`
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getTimeSlotFromTo(params: IGetNewTimeSlot) {
    const response = await this.apiService.get<any>("appointment/timeslotavailable", {
      ...params,
    })

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }
}
