import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { IApiResponse, getApiResponseStatus } from "domains/interfaces/apiResponse"
import { inject, injectable } from "inversify"
import type { IConfigService } from "./configService"
import type { ITokenService } from "./tokenService"
import { TYPES } from "./types"

export interface IApiService {
  getApiUrl(path: string): string
  get<T>(path: string, params?: unknown, useAuth?: boolean): Promise<IApiResponse<T>>
  post<T>(path: string, data?: unknown, useAuth?: boolean): Promise<IApiResponse<T>>
  put<T>(path: string, data?: unknown, useAuth?: boolean): Promise<IApiResponse<T>>
  patch<T>(path: string, data?: unknown, useAuth?: boolean): Promise<IApiResponse<T>>
  delete<T>(
    path: string,
    params?: unknown,
    useAuth?: boolean,
    data?: unknown
  ): Promise<IApiResponse<T>>
  //form data
  postFormData<T>(path: string, data?: unknown, useAuth?: boolean): Promise<IApiResponse<T>>
}

@injectable()
export class ApiService implements IApiService {
  private configService: IConfigService
  private tokenService: ITokenService

  private get authHeader(): AxiosRequestConfig {
    return {
      headers: { Authorization: `Bearer ${this.tokenService.accessToken}` },
    }
  }

  constructor(
    @inject(TYPES.IConfigService) configService: IConfigService,
    @inject(TYPES.ITokenService) tokenService: ITokenService
  ) {
    this.configService = configService
    this.tokenService = tokenService
  }

  private mapToIApiResponse<T>(axiosResponse: AxiosResponse<T>): IApiResponse<T> {
    return {
      status: axiosResponse && getApiResponseStatus(axiosResponse.status),
      data: axiosResponse && axiosResponse.data,
    }
  }

  public getApiUrl(path: string): string {
    const url = new URL(path, this.configService.baseApiUrl)
    return url.href
  }

  public async get<T>(path: string, params?: unknown, useAuth = false): Promise<IApiResponse<T>> {
    let response: AxiosResponse<T>
    try {
      response = await axios.get<T>(this.getApiUrl(path), { params, ...this.authHeader })
    } catch (error) {
      response = error.response as AxiosResponse<T>
    }
    return this.mapToIApiResponse(response)
  }

  public async post<T>(path: string, data?: unknown, useAuth = false): Promise<IApiResponse<T>> {
    let response: AxiosResponse<T>
    try {
      response = await axios.post<T>(this.getApiUrl(path), data, this.authHeader)
    } catch (error) {
      response = error.response as AxiosResponse<T>
    }
    return this.mapToIApiResponse(response)
  }

  public async postFormData<T>(
    path: string,
    data?: unknown,
    useAuth = false
  ): Promise<IApiResponse<T>> {
    let response: AxiosResponse<T>
    try {
      const options: any = {
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
          ...this.authHeader.headers,
        },
        data: data,
        url: this.getApiUrl(path),
      }
      response = await axios(options)
    } catch (error) {
      response = error.response as AxiosResponse<T>
    }
    return this.mapToIApiResponse(response)
  }

  public async put<T>(path: string, data?: unknown, useAuth = false): Promise<IApiResponse<T>> {
    let response: AxiosResponse<T>
    try {
      response = await axios.put<T>(this.getApiUrl(path), data, this.authHeader)
    } catch (error) {
      response = error.response as AxiosResponse<T>
    }
    return this.mapToIApiResponse(response)
  }

  public async patch<T>(path: string, data?: unknown, useAuth = false): Promise<IApiResponse<T>> {
    let response: AxiosResponse<T>
    try {
      response = await axios.patch<T>(this.getApiUrl(path), data, this.authHeader)
    } catch (error) {
      response = error.response as AxiosResponse<T>
    }
    return this.mapToIApiResponse(response)
  }

  public async delete<T>(
    path: string,
    params?: unknown,
    useAuth = false,
    data?: unknown
  ): Promise<IApiResponse<T>> {
    let response: AxiosResponse<T>
    try {
      response = await axios.delete<T>(this.getApiUrl(path), { params, data, ...this.authHeader })
    } catch (error) {
      response = error.response as AxiosResponse<T>
    }
    return this.mapToIApiResponse(response)
  }
}
